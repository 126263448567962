<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content" v-if="!viLoader">
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 11px"
                  ><i class="fas fa-book-reader"></i
                ></span>
                <h1>School Account</h1>
              </div>
            </div>
            <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%; background: none">
                <input
                  type="radio"
                  name="tabs"
                  id="academictab"
                  checked="checked"
                />
                <label for="academictab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">SUBSCRIPTION</label>
                <div class="tab">
                  <!-- <div id="page-content"> -->
                    <div class="row">
                      <div class="col-lg-12">
                        <div >
                          <div class="form-row">
                              <div class="col-lg-12">
                                <div class="admintbl mt-1 p-3">
                                  <table border="1">
                                    <thead>
                                      <tr>
                                        <th scope="col" rowspan="2">
                                          Plan Name<span class="required"
                                            >*</span
                                          >
                                        </th>
                                        <th scope="col" rowspan="2">
                                          Plan Frequency<span class="required"
                                            >*</span
                                          >
                                        </th>
                                        <th scope="col" rowspan="2">
                                          Plan Start Date<span class="required"
                                            >*</span
                                          >
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td data-label="Fee Name">
                                          <input
                                            type="text"
                                            name="Plan Name"
                                            disabled
                                            value="SCHOOL ONLINE - Enroll - YEARLY"
                                            class="form-control form-input"
                                            id="Plan Name"
                                            placeholder="Plan Name"
                                          />
                                        </td>
                                        <td data-label="prefix">
                                          <input
                                            type="text"
                                            name="Plan Frequency"
                                            disabled
                                            value="Yearly"
                                            class="form-control form-input"
                                            id="Plan Frequency"
                                            placeholder="Plan Frequency"
                                          />
                                        </td>
                                        <td data-label="prefix">
                                          {{ createDate | dateFormat }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                         
                          <div class="widjetfooter text-center">
                            <div class="dk_iconsml">
                              <button
                                type="button"
                                class="btn btnsml"
                                @click.prevent="redirectRoute('/subscription/plan/upgrade')"
                               
                              >
                              <!-- @click.prevent="goOnlinePayment()" -->
                                <span>UpGrade</span>
                              </button>
                              <!-- <button type="button" class="btn btnsml"  >
                                    <span>UpGrade</span>
                                    </button> -->
                              <button style="margin-right: 28px;margin-left: 4px;"
                                type="button"
                                class="btn btnsml"
                                @click="$router.go(-1)"
                              >
                                
                                <span>Back</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  <!-- </div> -->
                </div>
                <input
                  type="radio"
                  name="tabs"
                  id="departtab"
                  checked="checked"
                />
                <label for="departtab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">SETTINGS </label>
                <div class="tab">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="admintbl mt-1 p-3">
                        <table border="1">
                          <thead>
                            <tr>
                              <th scope="col" rowspan="5">
                                Field Name<span class="required">*</span>
                              </th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td data-label="Fee Name">
                                <input
                                  value="Terms And Conditions"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td colspan="3" data-label="prefix">
                                <input
                                  style="text-align: center"
                                  disabled
                                  :value="termsAndConditionDescription"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <!-- <td data-label="format">
                                <input
                                  disabled
                                  value="--"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td data-label="Fee Name">
                                <input
                                  disabled
                                  value="--"
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td> -->
                              <td>
                                <button
                                  class="btn round orange tooltipt"
                                  v-b-modal.editTermsAndCondition
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td data-label="Fee Name">
                                <input
                                  value="School support email"
                                  disabled
                                  type="text"
                                  class="form-control form-input"
                                />
                              </td>
                              <td colspan="3" data-label="prefix">
                                <input
                                  style="text-align: center"
                                  v-model="supportMail"
                                  type="email"
                                  class="form-control form-input"
                                />
                              </td>

                              <td>
                                <button
                                  v-if="!supportMailEdit"
                                  @click="editSupportMail"
                                  class="btn round orange tooltipt"
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                                <button
                                  v-if="supportMailEdit"
                                  :disabled="!supportMail"
                                  @click="saveSupportMail"
                                  type="submit"
                                  class="btn btnsml"
                                >
                                  Save
                                </button>

                                <button
                                  type="button"
                                  class="btn btncl clsmdl"
                                  id="clbtn"
                                  v-if="supportMailEdit"
                                  @click="cancelSupportMail"
                                >
                                    
                                  <span>Cancel</span>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  v-if="subscriptions.length > 0"
                  type="radio"
                  name="tabs"
                  id="transactionHistory"
                  checked="checked"
                />
                <label v-if="subscriptions.length > 0" for="transactionHistory"
                  >TRANSACTION HISTORY</label
                >
                <div class="tab" v-if="subscriptions.length > 0">
                  <div id="page-content">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="widjet">
                          <div class="widjetcontent tbllist">
                            <div class="restable">
                              <div class="resrow resheader">
                                <div class="cell" style="width: 10%">S.No</div>
                                <div class="cell">Transaction ID</div>
                                <div class="cell">Date</div>
                                <div class="cell">Type</div>
                                <div class="cell">Status</div>
                                <div class="cell">Amount</div>
                              </div>

                              <div
                                class="resrow"
                                v-for="(data, index) in subscriptions"
                                :key="index"
                              >
                                <div class="cell" data-title="From Month">
                                  {{ index + 1 }}
                                </div>

                                <div class="cell" data-title="From Year">
                                  {{ data.transaction_id }}
                                </div>
                                <div class="cell" data-title="From Year">
                                  {{ data.date }}
                                </div>

                                <div class="cell" data-title="From Month">
                                  {{ data.type }}
                                </div>
                                <div class="cell" data-title="From Month">
                                  {{ data.status }}
                                </div>
                                <div class="cell" data-title="From Year">
                                  {{ data.amount }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="dispflex mt-3 pt-3"
                      style="border-top: 1px solid #e5e5e5"
                    >
                      <div class="dk_icon"></div>
                      <span class="required"
                        >* Note: You can email us at adminuser@vidhyaan.com or
                        call us at for any clarifications.
                      </span>
                      <div class="text-right dk_iconsml"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="editTermsAndCondition" style="width: 100%">
        <button type="button" class="close"  @click.prevent="closeTermsAndCondition">×</button>

        <div class="contact-form">
          <h2>Update</h2>
          <br />
          <div>
            <form data-vv-scope="editInvoiceAutoNumfig" accept-charset="utf-8">
              <div class="form-row">
                <div class="form-group input-group col-md-12 pr-md-3 mt-3">
                  <span class="web-has-float-label">
                    <textarea
                      class="form-control web-form-input"
                      style="min-height: 135px; resize: none"
                      id="message"
                      v-model="termsAndConditionDescription"
                    ></textarea>

                    <label for="message"
                      >Terms And Conditions<span class="required"
                        >*</span
                      ></label
                    >
                  </span>
                </div>
              </div>
            </form>

            <div
              class="dispflex mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <div class="dk_icon"></div>
              <div class="text-right dk_iconsml">
                <button
                  type="button"
                  :disabled="!termsAndConditionDescription"
                  class="btn btnsml"
                  @click.prevent="saveTermsAndCondition"
                >
                  <span>Save</span>
                </button>

                <button
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                  @click.prevent="closeTermsAndCondition"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <vi-spinner
      v-if="viLoader"
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="
        width: 100%;
        z-index: 111;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 1rem;
      "
    />
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "SUBSCRIPTION",

  data() {
    return {
      viLoader: false,
      details: {},
      totalStudent: 25,
      createdStudent: 0,
      subscriptions: [],
      invoice: {},
      supportMailEdit: false,
      supportMail: "",
      termsAndConditionDescription: "",
      reType: "Auto-Renewal",
      createDate: new Date(),
    };
  },
  created() {
    this.checkCustomerSubscriptionDetails();
    this.getPlanDetails(true);
  },

  watch: {
    supportMail(value) {
      this.supportMail = value;
      this.validateEmail(value);
    },
  },

  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },

  methods: {
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    async goOnlinePayment(){
      
      let userData = secureUI.sessionGet("user");
 
       if (!userData) {
         this.$router.push('/login');
       } else {
           let url = 'https://subscriptions.zoho.in/subscribe/c005688fc9b725dc0b5756c08bf083badc1a4921d2db0ed80823bcb88ecfdc2b/V50'
           let urlObj = `${url}?cf_accountid=${userData.userDetails.account._id}&email=${userData.userDetails.email}&mobile=${userData.userDetails.phoneNo}`
           
           window.open(urlObj)
        
          
       }
     },

    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.errorMessage = "";
      } else {
        this.errorMessage = "Invalid Email";
      }
    },

    closeTermsAndCondition() {
      this.$bvModal.hide("editTermsAndCondition");
    },
    editSupportMail() {
      this.supportMailEdit = true;
    },
    cancelSupportMail() {
      this.supportMailEdit = false;
    },
    async getPlanDetails(value) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        if (value) {
          this.viLoader = true;
        }

        const response = await ViService.viXGet(
          "/account/getAccountConfigDetails?accountId=" +
            userData.userDetails.account._id,
          userData.token
        );

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);

          this.termsAndConditionDescription =
            details.termsAndConditionDescription || "";
          this.supportMail = details.supportMail || details.email;
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        this.viLoader = false;
      }
    },
    async saveTermsAndCondition() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          termsAndConditionDescription: this.termsAndConditionDescription,
        };

        const response = await ViService.viXPut(
          "/department/updateDepartmentConfig",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.closeTermsAndCondition();
          this.getPlanDetails(false);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    validateEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },

    async saveSupportMail(event) {
      event.preventDefault();
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        
        if (this.validateEmail(this.supportMail)) {
          let obj = {
            supportMail: this.supportMail,
          };
          const response = await ViService.viXPut(
            "/department/updateDepartmentConfig",
            obj,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
            this.cancelSupportMail();
            this.getPlanDetails(false);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        } else {
          this.$toasted.error("Email is invalid");
        }
      }
    },

    async checkCustomerSubscriptionDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        if (userData.userDetails.account.customerId) {
          const response = await ViService.viXGet(
            "/account/getFreePlanDetails?customerId=" +
              userData.userDetails.account.customerId,
            userData.token
          );

          if (response.isSuccess) {
            const details = response.data.transactions || [];

            if (details.length > 0) {
              this.subscriptions = details.filter((x) => x.type == "payment");
            }
          } else {
            this.subscriptions = [];
          }
        }

        this.createDate =
          userData.userDetails.account.createdDate || new Date();
      }
    },
  },
  components: {
    "plan-comp": () => import("../../views/ViPlanDetails.vue"),
    ViSpinner,
  },
};
</script>
